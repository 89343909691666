:global .label {
  display: flex;
  font-size: 1.3rem;
}

.input {
  display: flex;
  font-size: 1.4rem;
  border-radius: 2px;
}
.amplifyInput {
  border-radius: 2px;
}
:global .ant-input.is-danger {
  border: 1px solid red;
}
:global {
  .field:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .label {
    font-weight: normal;
  }
}
.invalidText {
  font-size: 1rem;
}

.formGroup {
  max-width: 450px;
  .labelContainer {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    :global {
      .label {
        margin-bottom: 0;
      }
    }
    .editLabelInputContainer {
      display: flex;
      align-items: center;
      .editLabelInput {
        border: 1px solid #cccccc;
        border-radius: 4px;
        width: fit-content;
        color: black;
        padding: 6px;
        &:focus {
          outline: none;
          border: 1px solid #cccccc;
          border-radius: 4px;
        }
      }
      .actionIcon {
        margin-left: 1rem;
        height: 15px;
        width: 15px;
        cursor: pointer;
      }
    }
    .originalLabelContainer {
      display: flex;
      align-items: center;
      .originalLabel {
        max-width: 600px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 1rem;
        color: black;
        word-break: break-all;
      }
      .originalLabelEditIcon {
        cursor: pointer;
      }
    }
  }
  .inputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    input,
    textarea {
      margin-right: 20px;
    }
    .actionIcon {
      cursor: pointer;
    }
  }
  .amplifyInput {
  }
}

@media screen and (max-width: 600px) {
  .label {
    color: #444444;
  }
}

@media screen and (width: 768px) {
  .label {
    color: #444444;
  }
}
.save {
  color: var(--primary-accent-color);
  cursor: pointer;
}
