@use '../../../common/scss/variables.module.scss' as *;

.circle {
  & :global {
    .ant-checkbox-inner {
      border-radius: 50%;
    }
  }
}

.small {
  :global {
    .ant-checkbox-inner::after {
      width: 4px;
      height: 7px;
    }

    .ant-checkbox-inner {
      width: 12px;
      height: 12px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 1px solid #fff;
      border-top: 0;
      border-left: 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-accent-color);
      border-color: var(--primary-accent-color);
    }
  }
}
