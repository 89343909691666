.invitationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invitationContent {
  display: flex;
  flex-direction: row;
  // align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 80px;
}

.invitationTextSection {
  flex: 1;
  max-width: 500px;
}

.inviteTitle {
  font-size: 32px;
  font-weight: bold;
  color: #000;
  font-family: TT-Commons;
  margin-bottom: 20px;
}

.inviteText {
  font-size: 20px;
  color: #4a4a4a;
  margin: 10px 0;
  font-family: TT-Commons-Light;
  font-weight: bold;
}

.primaryText {
  color: #2b6cb0;
}

.buttonContainer {
  margin-top: 20px;
  width: 280px;
}
.buttonContainer.hasIGError {
  width: 100%;
}

.facebookButton {
  background-color: #ff3960;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // max-width: 250px;
  width: 280px;
}

.invitationImageSection {
  flex: 1;
  text-align: center;
  position: relative;
}

.invitationImage {
  width: 100%;
  max-width: 400px;
}

.socialIcons {
  position: absolute;
  // top: 30%;
  right: 10%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

// .socialIcons img {
//   width: 24px;
//   height: 24px;
// }

.privacyPolicy {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  border-top: 1px solid rgba(17, 39, 57, 0.2);
  padding-top: 0.75rem;
  margin-top: 1.75rem;
  text-align: center;
  width: auto;
  font-family: TT-Commons;
}

.footerText {
  padding-top: 0.75rem;
  border-top: 1px solid rgba(17, 39, 57, 0.2);
}
.invalidInvite {
  background-color: #fff;
  padding: 10px;
  border: 2px solid #ff3960;
  border-radius: 5px;
  color: #666;
  display: flex;
  gap: 5px;
  align-items: center;
  span {
    font-size: 16px;
  }
}

.inviteAccepted {
  background-color: #fff;
  padding: 10px;
  border: 2px solid #79c32f;
  border-radius: 5px;
  color: #666;
  display: flex;
  gap: 15px;
  align-items: center;
  span {
    font-size: 16px;
  }
}

.listFbPagesContainer {
  flex: 1;
  max-width: 450px;
  margin-left: 20px;
  :global {
    .fb-list {
      height: 250px;
      padding: 3px;
      overflow-y: auto;
      li {
        display: block;
      }
    }
    .heading {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      font-family: TT-Commons;
      margin-bottom: 10px;
    }
    ul {
      // height: 300px;
    }
  }
}
.linkButton {
  border: 2px solid #ff3960;
  background-color: #ffff;
  color: white;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #4a4a4a;
  width: 100%;
  margin-top: 10px;
  :global {
    svg {
      height: 30px;
    }
  }
}
.linkButton:disabled {
  background-color: #e8e8e8;
  color: rgba($color: #000000, $alpha: 0.3);
  border-color: rgba($color: #ff3960, $alpha: 0.3);
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .invitationContent {
    flex-direction: column;
    padding: 40px;
  }
  .invitationTextSection {
    max-width: 100%;
  }
  .invitationImageSection {
    margin-top: 20px;
  }
  .listFbPagesContainer {
    margin-left: 0;
    margin-top: 20px;
  }
  .buttonContainer {
    width: 100%;
  }
  .facebookButton {
    width: 100%;
  }
  .linkButton {
    width: 100%;
  }
}
