.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  // background-color: #f5f5f5;
  padding: 40px;
  text-shadow: none;
}

.card {
  width: 100%;
  max-width: 600px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.description {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  margin-bottom: 10px;
}

.textarea {
  margin-top: 15px;
}

.button {
  margin-top: 15px;
  width: 100%;
}

.successMessage {
  text-align: center;
  color: #52c41a;
}

.successIcon {
  font-size: 40px;
  margin-bottom: 10px;
}

.successTitle {
  font-size: 18px;
  font-weight: bold;
}

.successDescription {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.modalContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.warningIcon {
  font-size: 20px;
  color: #faad14;
}

.deleteCheck {
  text-align: left;
}
